<template>
  <div class="auth-wrapper auth-v1 px-2 reset-password-page">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <AppLogoWithText />
        </b-link>

        <b-card-title class="mb-1">
          Reset Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter a new password
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="resetPasswordForm"
        >

          <!-- password -->
          <b-form
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Confirm Password"
              label-for="confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="confirm-password"
                    v-model="confirmPassword"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="confirm-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              <div v-if="isLoading">
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  small
                  label="Small Spinner"
                  type="grow"
                />
              </div>
              <div v-else>
                Reset Password
              </div>
            </b-button>
          </b-form>

          <b-alert
            v-show="message"
            :variant="alertVariant"
            show
          >
            <div class="alert-body">
              <span>{{ message }}</span>
            </div>
          </b-alert>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link
            class="tw-flex tw-items-center tw-justify-center"
            :to="{name:'auth-login'}"
          >
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert, BSpinner, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { $themeConfig } from '@themeConfig'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import AppLogoWithText from '@/components/AppLogoWithText.vue'

export default {
  components: {
    AppLogoWithText,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BForm,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    const { appTextLogoImage } = $themeConfig.app
    return {
      password: '',
      confirmPassword: '',
      message: null,
      alertVariant: 'success',
      isLoading: false,
      // validation
      required,
      appTextLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    await new Promise(resolve => {
      setTimeout(resolve, 10000)
    })
  },
  methods: {
    validationForm() {
      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$store.dispatch('auth/passwordReset', { token: this.token, newPassword: this.password }).then(response => {
            if (response.message) {
              this.message = response.message
            }
            this.alertVariant = 'success'
            this.$store.commit('auth/updateLoginFormSuccessMessage', 'Your password has been changed. Please sign in with your credentials below.')
            this.$router.push({ name: 'auth-login' })
          }).catch(e => {
            this.alertVariant = 'danger'
            this.message = e.message
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
